//
// Custom Example 2 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//

.my-custom-style {
	padding: 1rem 0.5rem;
	color: kt-brand-color();

	.my-custom-element {
		margin-left: 1rem;
	}
}

@include kt-tablet-and-mobile {
	.my-custom-style {
		padding: 0.5rem 0.25rem;

		.my-custom-element {
			margin-left: 0.5rem;
		}
	}
}

.flex-grow-1 {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-moz-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.calender-outline {
	overflow-x: auto;

	.rbc-header {
		padding: 12px;
		text-align: start;

		span {
			text-transform: uppercase !important;
			color: var(--secondary-07, #8F9BBA);
			/* font-feature-settings: 'clig' off, 'liga' off; */
			/* Poppin/Po14M (14Medium_20) */
			font-family: Poppins !important;
			font-size: 14px !important;
			font-style: normal !important;
			font-weight: 500 !important;
			line-height: 20px !important;
			/* 142.857% */
		}
	}

	.rbc-date-cell {
		padding: 12px !important;
		text-align: start !important;

		color: var(--secondary-06, #818DAB) !important;
		/* font-feature-settings: 'clig' off, 'liga' off; */
		/* Poppin/Po20R (20Regular_30) */
		font-family: Poppins !important;
		font-size: 20px !important;
		font-style: normal !important;
		font-weight: 400 !important;
		line-height: 30px !important;
		/* 150% */
	}

	.rbc-day-bg.rbc-today {
		background: #fff !important;
	}

	.rbc-date-cell.rbc-now a {
		position: absolute;
		min-width: 30px !important;
		text-align: center;
		border-radius: 90px;
		background: var(--primary-06, #338FEB);
		color: var(--white-white-500, #FFF);
		// padding: 5px;
	}

	.rbc-calendar {
		min-height: 700px;
		min-width: 700px;
	}

	.rbc-event {
		background: none;
		padding: 0;

		&:focus {
			outline: none;
		}

		&.rbc-selected {
			background: none;
		}

		.event-type-offline {
			display: flex;
			align-items: center;
			gap: 10px;
			border-radius: 4px;
			padding: 2px 8px;
			background: #e86a95;

			text-align: center;
			font-feature-settings: 'clig' off, 'liga' off;
			/* Poppin/Po12M (12Medium_18) */
			font-family: Poppins;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 18px;
			/* 150% */
		}

		.event-type-online {
			display: flex;
			align-items: center;
			gap: 10px;
			border-radius: 4px;
			padding: 2px 8px;
			background: var(--primary-09, #DAEBFB);
			color: var(--primary-06, #338FEB);
			text-align: center;
			font-feature-settings: 'clig' off, 'liga' off;
			/* Poppin/Po12M (12Medium_18) */
			font-family: Poppins;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 18px;
			/* 150% */
		}
	}

	.rbc-toolbar {
		margin-bottom: 16px;

		.rbc-toolbar-label {
			text-transform: uppercase;
			font-weight: 700;
			font-size: 30px;
		}

		.rbc-btn-group button {
			text-transform: capitalize;
		}
	}
}

.rbc-overlay {
	z-index: 99 !important;

	.rbc-event {
		background: none;
		padding: 0;

		&:focus {
			outline: none;
		}

		&.rbc-selected {
			background: none;
		}

		.event-type-offline {
			display: flex;
			align-items: center;
			gap: 10px;
			border-radius: 4px;
			padding: 2px 8px;
			background: #e86a95;

			text-align: center;
			font-feature-settings: 'clig' off, 'liga' off;
			/* Poppin/Po12M (12Medium_18) */
			font-family: Poppins;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 18px;
			/* 150% */
		}

		.event-type-online {
			display: flex;
			align-items: center;
			gap: 10px;
			border-radius: 4px;
			padding: 2px 8px;
			background: var(--primary-09, #DAEBFB);
			color: var(--primary-06, #338FEB);
			text-align: center;
			font-feature-settings: 'clig' off, 'liga' off;
			/* Poppin/Po12M (12Medium_18) */
			font-family: Poppins;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 18px;
			/* 150% */
		}
	}
}

.inquiryStatusNew {
	background-color: #dc3545 !important;
	font-weight: bold;
}

.kt-aside--minimize .kt-aside__brand .kt-aside__brand-logo a {
	display: none;
}

.colorTextColor {
	width: 70px;
	height: 30px;
	border-radius: 8px,
}

.popover {
	position: absolute;
	z-index: 2;
	left: 50%;
}

.popover-2 {
	position: absolute;
	z-index: 2;
	left: 82px;
}

.quiz__question-main {
	max-height: 395px;
	overflow-y: auto;
	overflow-x: hidden;
}

.quiz__checkbox {
	padding: 14px 18px;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;

	.ant-radio-inner {
		border: 2px solid #969696;
		background: #fff;

		&::after {
			content: none;
		}
	}

	.ant-radio-checked .ant-radio-inner {
		border: 5px solid #0066cc;
	}

	&.correct {
		color: #27ae60;

		.ant-radio-checked .ant-radio-inner {
			border-color: #27ae60;
		}
	}

	&.incorrect {
		color: #f24343;

		.ant-radio-checked .ant-radio-inner {
			border-color: #f24343;
		}
	}
}

.list-choice .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
	&.correct {
		color: #27ae60;

		.ant-checkbox-checked {
			.ant-checkbox-inner {
				background: #27ae60;
				border: 2px solid #27ae60;
			}
		}
	}

	&.incorrect {
		color: #f24343;

		.ant-checkbox-checked {
			.ant-checkbox-inner {
				background: #f24343;
				border: 2px solid #f24343;
			}
		}
	}
}

.quiz__button-list {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 10px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.lesson__button-list {
	position: relative;
	left: 0;
	right: 0;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.quiz__button-list-bottom {
	position: static;
}

.quiz__button {
	background: #4472c4;
	border: 1px solid transparent;
	color: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;

	&:hover {
		border: 1px solid #4472c4;
		color: #4472c4;
		background: transparent;
	}
}

.quiz__select-type {
	.clue {
		background: #ffffff;
		border: 1px solid #e0e0e0;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		border-radius: 8px;
		padding: 9px 16px;
	}

	.quiz__select-item {
		.ant-select-selector {
			height: 40px;
			background: #ffffff;
			border: 1px solid #e0e0e0;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			-webkit-border-radius: 8px;
			-moz-border-radius: 8px;
			border-radius: 8px;
		}

		.ant-select-selection-item {
			height: 40px;
			line-height: 40px !important;
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;
			color: #333333;
		}
	}
}

.quiz__modal-confirm {
	.ant-modal-content {
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;
	}
}

.quiz__result {
	padding: 30px 10px 30px 20px;
}

.quiz__result-info {
	background: #f3f7fc;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;

	.item {
		padding: 10px 20px;
		border-bottom: 1px solid #ebebeb;

		&:last-child {
			border-bottom: 0;
		}
	}
}

.result-icon {
	position: absolute;
	top: 8px;
	right: 8px;
}

.correct-answer__list {
	padding-top: 16px;
	margin-top: 4px;
}

.question-list {
	max-height: 505px;
	overflow-y: auto;
}

.question-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;

	&.active {
		.question-item__info {
			color: #27ae60;
		}
	}
}

.question-item__number {
	max-width: 50px;
	position: relative;

	.ant-progress-inner {
		width: 50px !important;
		height: 50px !important;
	}

	.ant-progress-text {
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		color: #747474;
	}
}

.course-materials {
	border-top: 1px solid #f1f1ef;
	padding-top: 15px;
	margin-top: 15px;
}

.material-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;

	.material-item {
		cursor: pointer;
		min-width: 56px;
		max-width: 56px;
		height: 60px;
		background: #eeeeee;
		-webkit-border-radius: 17px;
		-moz-border-radius: 17px;
		border-radius: 17px;
		overflow: hidden;
		font-weight: 500;
		font-size: 18px;
		line-height: 27px;
		color: #747474;
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-moz-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-moz-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		margin: 0 10px 10px 0;

		img {
			max-width: 36px;
			max-height: 36px;
		}
	}
}

.questions-dot {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 -2px;
	height: 4px;

	.item {
		width: 100%;
		padding: 0 2px;

		span {
			background: rgba(154, 230, 180, 0.5);
			-webkit-border-radius: 100px;
			-moz-border-radius: 100px;
			border-radius: 100px;
			width: 100%;
			height: 4px;
			display: inline-block;
		}

		&.active {
			span {
				background: #27ae60;
			}
		}

		&.error {
			span {
				background: #ff4d4f;
			}
		}
	}
}


.f-300 {
	font-weight: 300;
}

.f-400 {
	font-weight: 400;
}

.f-500 {
	font-weight: 500;
}

.f-600 {
	font-weight: 600;
}

.f-700 {
	font-weight: 700;
}

.f-10 {
	font-size: 10px;
	line-height: 15px;
}

.f-12 {
	font-size: 12px;
	line-height: 16px;
}

.f-14 {
	font-size: 14px;
	line-height: 20px;
}

.f-15 {
	font-size: 15px;
	line-height: 24px;
}

.f-16 {
	font-size: 16px;
	line-height: 24px;
}

.f-17 {
	font-size: 17px;
	line-height: 26px;
}

.f-18 {
	font-size: 18px;
	line-height: 28px;
}

.f-20 {
	font-size: 20px;
	line-height: 28px;
}

.f-22 {
	font-size: 22px;
	line-height: 30px;
}

.f-24 {
	font-size: 24px;
	line-height: 32px;
}

.f-26 {
	font-size: 26px;
	line-height: 40px;
}

.f-30 {
	font-size: 26px;
	line-height: 45px;
}

.f-32 {
	font-size: 32px;
	line-height: 40px;
}

.f-38 {
	font-size: 38px;
	line-height: 58px;
}

.f-48 {
	font-size: 48px;
	line-height: 52px;
}

.color-333 {
	color: #333;
}

.color-23 {
	color: #27ae60;
}

.color-26 {
	color: #f24343;
}

.modal-title-name {
	.modal-course-name {
		background-color: beige;
		margin-bottom: 5px;
		padding: 5px;
	}

	.modal-course-content {
		padding-left: 30px;
		margin-bottom: 5px;

		.modal-content {
			min-height: 30px;
			padding: 0 10px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 5px;
		}
	}
}

.container-preview {
	display: flex;
	margin-top: -40px;

	.left-container::-webkit-scrollbar {
		width: 6px
	}

	.left-container::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	.left-container::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 10px;
	}

	.left-container {
		width: 50%;
		min-height: 80vh;
		height: 100vh;
		overflow-y: scroll;
		// background-image: linear-gradient(145deg, #5ebeff, #d3dde9);
		background-color: #d3dde9;

		.logo-container {
			background-color: #d3dde9;
			z-index: 10;
			height: auto;
			padding-bottom: 25px;

			.container-image {
				width: 35%;

			}

			display: flex;
			justify-content: center;
			position: sticky;
			top: 0;
			padding-top: 50px;
			margin: 0 auto;
			width: auto;
			//background-image: url(https://s3.ap-southeast-1.amazonaws.com/eamd-staging/MyTraining/1634008459178-t1-png.png);
		}

		.preview-introduction {
			font-size: 40px;
			font-weight: bold;
			height: 150px;
			//padding-top: 300px;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			text-align: center;
		}

		.total-pass {
			width: 45%;
			justify-content: center;
			display: flex;
			margin-left: -10px;
		}

		.preview-text {
			font-size: 20px;
			padding-top: 70px;
			padding-left: 50px;
			font-weight: bold;
			width: 80%;
			display: flex;
			align-items: flex-end;
			justify-content: flex-start;
		}

		.preview-text-intro {
			font-size: 20px;
			padding-top: 30px;
			padding-left: 50px;
			font-weight: medium;
			width: 95%;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			text-align: inherit;
		}
	}

	.right-container {
		width: 50%;
		min-height: 80vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.card-content {
			margin-top: 50px;
			height: 140px;
			display: flex;
			width: 70%;
			cursor: pointer;

			.card-content-left {
				background-image: url(https://cdn.easygenerator.com/images/media/section-default-image.jpg);
				background-size: cover;
				background-position: center;
				width: 25%;
				background-color: rgb(210, 209, 209);
			}

			.card-content-right {
				font-size: 20px;
				padding: 10px 0 0 20px;
				font-weight: bold;
				width: 75%;
				background-color: #ffffff;
				position: relative;

				.span-next {
					position: absolute;
					bottom: 10px;
					right: 10px;
					font-size: 14px;
					display: flex;
					align-items: center;
					margin: 0;
					color: #c5c5c5;
					font-weight: normal;
				}

				.progress-section {
					.ant-progress-line {
						position: absolute;
						bottom: -7px;
						left: 0;

						.ant-progress-outer {
							.ant-progress-bg {
								height: 5px !important;
							}
						}
					}
				}
			}
		}
	}
}

.container-section {
	display: flex;
	margin-top: -40px;

	.left-container-section {
		width: 25%;
		min-height: 90vh;
		background-color: rgb(241, 240, 240);
		// justify-content: center;
		display: flex;
		flex-direction: column;
		align-items: center;

		.back-preview {
			width: 80%;
			min-height: 10vh;
			display: flex;
			justify-content: space-between;
			align-items: center;
			min-height: 20vh;
			font-size: 25px;

			.goback {
				display: flex;
				align-content: center;
				align-items: center;
				cursor: pointer;
				font-size: 16px;
			}

			.toogle-sidebar {
				//padding-left: 64px;
				padding-bottom: 10px;
				cursor: pointer;
			}
		}

		.left-section-content {
			ul {
				li {
					display: flex;
					height: 34px;

					p {
						display: flex;
					}
				}
			}

			.active-li {
				border-left: 3px solid #e9a744;
				padding-left: 13px;
				margin-left: -16px;
			}

			span {
				padding-right: 3px;
				height: 22px;
				display: flex;
				align-items: center;
			}

			ul {
				li {
					font-size: var(--font-size-12);
				}
			}

			width: 80%;
			min-height: 90vh;
		}

		.result-container-section {
			position: sticky;
			font-size: 18px;
			font-weight: bold;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 40px;
			height: 30px;
			display: flex;
			align-items: center;
			background: rgb(251, 248, 248);
			align-content: center;
			justify-content: flex-start;
		}
	}

	.right-container-section {
		width: 75%;
		min-height: 90vh;
		display: flex;
		flex-direction: column;
		background-color: rgb(255, 255, 255);
		padding-bottom: 20px;

		.toogle-sidebar {
			padding-top: 20px;
			width: 15%;
		}

		.result-button-section {
			margin-left: 100px;

			.next-section-button {
				width: 80%;
				display: flex;
				justify-content: flex-end;
			}
		}

		.toogle-content {
			margin-left: 100px;
		}

		.number-of-steps {
			margin-bottom: 30px;
			font-weight: bold;
		}

		.title-question {
			font-size: 30px;
			font-weight: bold;
			margin-bottom: 3
		}

		.logo-container {
			margin-left: 100px;
			margin-top: 60px;
			margin-bottom: 30px;
			padding-left: 100px;
			width: 255px;
			height: 115px;
			background-size: cover;
			object-fit: contain;
			background-position: center;
			background-image: url(https://s3.ap-southeast-1.amazonaws.com/eamd-staging/MyTraining/1634008459178-t1-png.png);
		}

		.toogle-content {
			margin-left: 100px;
		}

		.number-of-steps {
			margin-bottom: 30px;
			font-weight: bold;
		}

		.title-question {
			font-size: 30px;
			font-weight: bold;
			margin-bottom: 30px;
		}

		.submit-section-contain {
			padding-top: 20px;
		}

		.line-container {
			height: 1px;
			width: 80%;
			border: #747474 1px solid;
		}


		.container-section-contain-result {
			display: flex;
			justify-content: center;
		}

		.result-title {
			display: flex;
			justify-content: center;
			font: inherit;
			vertical-align: baseline;
			margin-bottom: 32px;
			margin-top: 32px;
			font-family: Arial, "PT Serif", serif;
			font-size: 26px;
			font-style: normal;
			font-weight: 600;
			text-decoration: none;
			line-height: 1.4;
			color: rgb(0, 0, 0);
		}

		.result-notification {
			display: flex;
			justify-content: center;
			font: inherit;
			vertical-align: baseline;
			margin-bottom: 32px;
			margin-top: 32px;
			font-family: Arial, "PT Serif", serif;
			font-size: 18px;
			font-style: normal;
			text-decoration: none;
			line-height: 1.4;
			color: rgb(0, 0, 0);
		}

		.submit-result-button {
			display: flex;
			justify-content: center;

			.result-button {
				display: flex;
				flex-direction: column;

				button:nth-child(1) {
					color: rgb(255, 255, 255);
					height: 40px;
					margin-bottom: 20px;
					width: 135px;
				}

				button:nth-child(2) {
					color: rgb(0, 0, 0);
					height: 50px;
					margin-bottom: 20px;
				}
			}
		}

		.container-section-contain-matching {
			display: flex;
			flex-direction: row;
			width: 80%;
			margin-bottom: 10px;

			.question-matching-number {
				padding-left: 20px;
				display: flex;
				align-items: center;
				width: 45%;
				height: 35px;
				background: rgba(0, 0, 0, 0.06);
				font-size: 18px;
				color: #000;
				font-weight: 500;
			}

			.plus-matching {
				width: 10%;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.question-matching-answer {
				display: flex;
				align-items: flex-start;
				width: 45%;
				height: 35px !important;
				border: 0;
			}
		}

		.container-section-contain-input {
			label {
				display: flex;
				align-items: flex-end;
				padding-right: 5px;
				font-weight: bold;
				font-size: 14px;
			}

			.input-fill-blank {
				width: 30%;
			}

			display: flex;
			padding-bottom: 5px;
		}

		.container-section-contain {
			p {
				width: 90%;
				text-align: justify;
			}

			ol {
				li {
					padding-bottom: 15px;
				}
			}

			.ranking-question-number {
				width: 25px;
				padding-right: 15px;
				font-size: 18px;
				text-align: center;
			}

			.ranking-question {
				cursor: grab;
				width: 75%;
				font-size: 18px;
				background-color: rgba(202, 200, 200, 0.954);
				padding-bottom: 5px;
				padding-left: 10px;
			}

			.tag-true-false {
				display: flex;

				.test {
					display: flex;
					position: relative;
					flex-direction: column;
					flex-shrink: 0;
					-webkit-box-align: center;
					align-items: center;
					align-self: self-start;
					-webkit-box-pack: center;
					justify-content: center;
					width: 50px;
					height: 24px;
					margin-right: 7px;
					padding: 2px 3px 4px;
					background-color: rgba(0, 153, 255, 0.5);
					border-radius: 5px;
					font-family: Arial, Arial, sans-serif;
					font-size: var(--font-size-12);
					font-weight: 600;
					text-align: center;
					color: rgb(255, 255, 255);

					&::after {
						content: "";
						position: absolute;
						top: 100%;
						left: 50%;
						margin-left: -4px;
						border-width: 4px;
						border-style: solid;
						border-color: rgba(0, 153, 255, 0.5) transparent transparent;
						border-image: initial;
					}
				}

			}

			.image-question {
				width: 80%;
				display: flex;
				justify-content: space-evenly;

				.image-question-active {
					border: #0066cc 3px solid;

				}

				.no-image-question {
					position: relative;
				}
			}

			margin-bottom: 10px;

			span {
				font-size: 16px;
			}
		}

	}

	.marker {
		position: absolute;
		width: 20px;
		height: 20px;
		display: block;
		cursor: pointer;
		background-color: rgba(255, 255, 255, 0.1);
		border-radius: 50%;
		outline: none;
		overflow: hidden;

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0px;
			width: 50%;
			right: 0px;
			bottom: 0px;
			background: linear-gradient(rgb(0, 153, 255) 0%, rgb(0, 153, 255) 50%, rgb(0, 153, 255) 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
		}

		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 0px;
			width: 50%;
			left: 0px;
			bottom: 0px;
			background: linear-gradient(to top, rgb(0, 153, 255) 0%, rgb(0, 153, 255) 50%, rgb(0, 153, 255) 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
		}
	}
}

.list-feature {

	ul {
		color: #57617C;
		list-style-type: unset;
		list-style-position: inside;
		display: inline-block;

		li {
			margin-bottom: 10px;
			color: #57617C;
			width: 200px;
		}
	}
}

.bg-lable-false {
	background-color: #f24343;

	&::after {
		border-color: #f24343 transparent transparent;
	}
}

.section-status {
	text-align: right;
}

.color-primary {
	color: #646c9a !important;
}

.li-content-err {
	border: 1px solid #d10202 !important;
}

.li-content {
	border: 1px solid #eee;
	padding: 10px;
	text-align: center;
	cursor: pointer;
	background: #eff3f5;
	box-shadow: 2px 3px #eee;
	margin-bottom: 10px;

	.form-control:focus,
	.form-control:active {
		background: transparent;

	}
}

.render-content-customer {
	padding: 10px;
	text-align: center;
	cursor: pointer;

	.form-control:focus,
	.form-control:active {
		background: transparent;

	}
}

.group-image-sort {
	display: flex;
	align-items: center;
}

.group-image-sort-error:has(.li-content-err) {
	.input-sesion-name-error {
		border: 1px solid #d10202 !important;
	}
}

// Feature Plan CSS

.feature-plan {
	padding: 0;

	.feature-section__header {
		background-color: #D9E1F2;
		width: 100%;
		padding: 0 2px;
		font-size: 17px;
		height: 35px;
		display: flex;
		align-items: center;
		font-weight: bold;
	}

	.feature-section__item {
		display: flex;
		min-height: 25px;
		//align-items: center;
		margin-bottom: 5px;

		.feature-section__item-title {
			width: 50%;
			padding-left: 5px;
			border: #747474 solid 1px;
			border-right: unset;
		}

		.feature-section__item-content {
			position: relative;
			width: 50%;
			padding: 0 10px 0;
			border: #747474 solid 1px;
			display: flex;
			//align-items: center;
			justify-content: space-between;

			.edit-feature-button {
				font-size: 15px;
				position: absolute;
				right: 12px;
			}
		}
	}
}

.button-modal {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.form-modal {
	margin-bottom: 10px;

	button {
		border-radius: 0px;
	}
}

.mt-auto {
	margin-top: auto;
}

.pt-20 {
	padding-top: 20px;
}

.mr-lr-40 {
	margin-left: 40px !important;
	margin-right: 40px !important;
}

.pt-13 {
	padding-top: 13px;
}

.width-90 {
	width: 90% !important;
}

.image-course {
	min-height: 170px;
	width: 100%;
	// margin-bottom: 12px;
	position: relative;
}

.logo-custom {
	position: absolute;
	top: 30%;
	width: 80%;
}

.thumb-custom {
	position: absolute;
	top: 2%;
}

.title-course {
	position: relative;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.create-referral-code {
	border: rgba(255, 184, 34, 0.1);
}

.modal-create-referral-code {
	border-radius: 10px;

	.ant-modal-header {
		border-bottom: none;
	}

	.ant-modal-body {
		padding-top: 0;
	}

	.ant-modal-footer {
		text-align: center;
		border-top: none;

		.ant-btn.ant-btn-primary {
			border: $primary;
			background-color: $primary;
			width: 25%;
		}

		.ant-btn.ant-btn-default {
			width: 25%;
		}
	}
}

.referral-code-card {
	border: none;

	.card-body {
		padding: 0 1.25rem !important
	}
}